<template>
  <div ref="matrix_3">
    <!-- <div style="overflow: auto"> -->
    <div class="table-container cm-table-w-scroll">
      <b-table-simple :sticky-header="stickyHeaderSize">
        <b-thead>
          <!-- <tr>
            <th class="div-header" colspan="100%">
              Matriz de Tributación de las Asignaturas al Perfil de Egreso
            </th>
          </tr> -->
          <b-tr>
            <!-- <th class="th-header" colspan="3"> -->
            <b-th class="th-header" colspan="3">
              Plan de
              <br />
              <span v-if="career">{{ career.name.toUpperCase() }}</span>
              <span v-else>... Cargando</span>
            </b-th>
            <template v-for="type in competenceTypes">
              <b-th
                class="th-header"
                :colspan="getMaxCompetencePerType(type.id)"
                :key="type.id"
                v-if="getMaxCompetencePerType(type.id) != 0"
              >
                <!-- rowspan="2" -->
                <!-- {{ $getVisibleNames("mesh.competence", true, "Competencias") }} -->
                {{ type.name.toUpperCase() }}
              </b-th>
            </template>
            <template
              ><b-th
                class="th-header"
                :colspan="getMaxCompetencePerType(null)"
                :key="'competence-type-null'"
                v-if="getMaxCompetencePerType(null) != 0"
              >
                {{
                  $getVisibleNames(
                    "mesh.competence",
                    true,
                    "Competencias"
                  ).toUpperCase()
                }}
              </b-th>
            </template>
          </b-tr>
          <b-tr>
            <b-th style="width: 10px !important" class="th-second-row"
              >Nivel</b-th
            >
            <!-- <th class="">Área de Formación</th> -->
            <b-th style="width: 20%" class="th-second-row">
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
            </b-th>
            <b-th style="width: 1%" class="th-second-row">
              {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
            </b-th>
            <b-th
              v-for="competence in sortcompentece"
              :key="competence.id"
              class="p-0 align-middle"
            >
              {{
                competence.profile_cycle
                  ? competence.profile_cycle.order + "."
                  : ""
              }}{{ competence.order
              }}<span
                v-if="
                  competence.sub_order > 0 &&
                  institution &&
                  institution.show_competence_sub_order
                "
                >.{{ competence.sub_order }}</span
              >
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <Matrix3Table
            v-for="item in sortedArray"
            :key="item.id"
            :matter="item"
            :matters="sortedArray"
            :cycles="cycles"
            :competences="sortcompentece"
            :egress_profile_id="egress_profile_id"
            :formationAreas="formationAreas"
          ></Matrix3Table>
        </b-tbody>
      </b-table-simple>
      <div v-if="competencesList.length == 0" class="empty-text">
        No hay
        {{ $getVisibleNames("mesh.competence", true, "Competencias") }} que
        tributen a este
        {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}.
      </div>
    </div>
    <!-- </div> -->
    <!-- <MeshCustomFab
      :egress_profile_id="egress_profile_id"
      :show_matrix3="false"
    ></MeshCustomFab> -->
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Matrix3",
  components: {
    Matrix3Table: () => import("@/components/mesh/Matrix3/Matrix3Table"),
    // MeshCustomFab: () => import("../MeshCustomFab"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    // cycle_id: {
    //   type: Number,
    //   required: true,
    // },
    isActive: {
      value: false,
    },
    printing: {
      value: false,
    },
  },
  data() {
    return {
      institution_id: this.$store.getters.getInstitutionId,
      title: "Expandir/Contraer",
      window_top: 0,
      current_table_height: null,
      stickyHeaderSize: "760px",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      formationAreas: names.FORMATION_AREAS,
      matters: names.MATTERS,
      competenceTypes: names.COMPETENCE_TYPES,
      competences: names.COMPETENCES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      profile_competences: names.PROFILE_COMPETENCES,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      egressProfileCycles: names.PROFILE_CYCLES,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    sortcompentece: function () {
      function order(a, b) {
        if (a.type_order > b.type_order) return 1;
        if (a.type_order < b.type_order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.sub_order < b.sub_order) return -1;
        if (a.sub_order > b.sub_order) return 1;
        return 0;
      }
      return [...this.competencesList].sort(order);
    },
    sortedArray: function () {
      function order(a, b) {
        if (a.plan_level < b.plan_level) return -1;
        if (a.plan_level > b.plan_level) return 1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      }
      return [...this.matterList].sort(order);
    },
    competencesList() {
      let list = this.profile_competences
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .map((profile_competence) => {
          const competence = this.competences.find(
            (x) => x.id == profile_competence.competence
          ) || {
            action: "",
            content: "",
            condition: "",
            context: "",
            type: null,
            verb: null,
            full_sentence: "",
            name: "",
          };
          let type_order = 1000;
          if (competence) {
            type_order = (
              this.competenceTypes.find((x) => x.id == competence.type) || {
                order: 1000,
              }
            ).order;
          }
          let profile_cycle_order = 0;
          const get_cycle = this.egressProfileCycles.find(
            (profile_cycle) =>
              profile_cycle.egress_profile ==
                profile_competence.egress_profile &&
              profile_cycle.cycle == profile_competence.cycle
          );
          if (get_cycle) {
            profile_cycle_order = get_cycle.order;
          }
          return {
            id: profile_competence.id,
            order: profile_competence.order,
            sub_order: profile_competence.sub_order,
            egress_profile: profile_competence.egress_profile,
            cycle: profile_competence.cycle,
            competence: profile_competence.competence,
            action: competence.action,
            content: competence.content,
            condition: competence.condition,
            context: competence.context,
            type: competence.type,
            verb: competence.verb,
            full_sentence: competence.full_sentence,
            name: competence.name,
            profile_cycle: get_cycle,
            type_order: type_order,
            profile_cycle_order: profile_cycle_order,
          };
        });
      return list;
      // return list.filter((x) => x.cycle == this.cycle_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      if (!this.egressProfile) return [];
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id) &&
          this.semesterAmount.includes(x.plan_level)
      );
    },
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      return this.egressProfileCycles
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .map((x) => x.cycle);
    },
    semesterAmount() {
      let min = 14;
      let max = 0;
      if (this.egressProfileCycleId.length == 0) {
        min = 1;
        max = this.egressProfile.semester_amount;
      } else {
        this.egressProfileCycles.forEach((cycle) => {
          if (
            cycle.init_semester < min &&
            cycle.egress_profile == this.egressProfile.id
          )
            min = cycle.init_semester;
          if (
            cycle.end_semester > max &&
            cycle.egress_profile == this.egressProfile.id
          )
            max = cycle.end_semester;
        });
      }
      if (max > this.egressProfile.semester_amount) {
        max = this.egressProfile.semester_amount;
      }
      let list = [];
      for (let index = min; index <= max; index++) {
        list.push(index);
      }
      return list;
    },
  },
  methods: {
    // redirectToMatrix2(cycle_id) {
    //   let egress_profile_id = this.egressProfile.id;
    //   this.$router.push({
    //     path: `/matrix2/form/${egress_profile_id}/${cycle_id}`,
    //   });
    // },
    updateStickyHeaderSize() {
      const viewportHeight = window.innerHeight;
      const newStickyHeaderSize = viewportHeight * 1 + "px"; // Por ejemplo, 100% de la altura del viewport
      this.stickyHeaderSize = newStickyHeaderSize;
    },
    getMaxCompetencePerType(type) {
      let max = 0;
      let list = [];
      let competences = this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
            });
          }
        }
      });
      if (!this.cycle_id) {
        list.forEach((element) => {
          if (element.type == type) {
            max += 1;
          }
        });
      }
      // else {
      //   list.forEach((element) => {
      //     if (element.type == type && element.cycle == this.cycle_id) {
      //       max += 1;
      //     }
      //   });
      // }
      return max;
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
  },
  watch: {
    egressProfile: function () {
      if (this.egressProfile)
        this.$store.dispatch(names.FETCH_CAREER, this.egressProfile.career);
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    setTimeout(() => {
      this.current_tab_height = this.$refs.matrix_3.clientHeight;
    }, 1500);
  },
  mounted() {
    this.updateStickyHeaderSize();
    window.addEventListener("resize", this.updateStickyHeaderSize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.updateStickyHeaderSize);
  },
};
</script>
<style scoped>
.th-second-row {
  align-items: center;
  padding: 2px 4px !important;
}
table {
  width: 100% !important;
}
th {
  background-color: var(--primary-color) !important;
  color: var(--primary-font-color) !important;
  font-size: var(--secondary-font-size) !important;
  border: 1px solid black !important;
}
td {
  padding: 0px !important;
}

.th-header {
  height: 5rem !important;
  vertical-align: middle !important;
}

.rotated {
  transform: rotate(270deg) !important;
}
.table-container {
  /* overflow: scroll;
  height: 40rem; */
  text-align: center !important;
  width: 100% !important;
}
.div-header {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--kl-menu-hover-color) !important;
  color: var(--primary-font-color) !important;
  min-width: 60px !important;
  width: 100% auto !important;
  margin-bottom: 0px !important;
}
.empty-text {
  background: rgb(245, 245, 245) !important;
  margin: auto !important;
  padding: 0.2em 1em !important;
  width: 82vh !important;
  border-radius: 3px !important;
  font-weight: bold !important;
  font-size: 11pt !important;
}
/* .cm-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cm-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cm-scrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.cm-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #919191;
} */

.cm-table-w-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  /* max-height: 760px; */
}

.cm-table-w-scroll table {
  width: 100%;
  margin: auto;
  /* border-collapse: separate; */
  border-spacing: 0;
}

.cm-table-w-scroll table th,
.cm-table-w-scroll table td {
  font-size: 13px;
  padding: 5px 10px;
  vertical-align: top;
  white-space: nowrap;
}

.cm-table-w-scroll thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: 1px solid black !important;
  font-weight: 600;
}
/* .thd {
  vertical-align: middle !important;
  border: 1px solid black !important;
  border-width: thin !important;
  text-align: center !important;
  font-size: var(--thirth-font-size) !important;
  height: 7rem !important;
  letter-spacing: 0.1px !important;
  max-width: 4.5rem !important;
  width: 3.5rem !important;
  margin: 0px !important;
  padding: 12px 1px !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
  text-overflow: ellipsis !important;
  vertical-align: top !important;
} */
/* .th-plan-level {
  width: 5% !important;
} */

@media (max-width: 600px) {
  .empty-text {
    width: 75vh !important;
  }
}

@media print {
  /* .cm-table-w-scroll {
    overflow: visible !important;
    max-height: 100% !important;
  } */
}
/* .competences {
  margin-left: -7px;
  margin-right: -9px;
  word-spacing: normal;
  max-height: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 12px;
  transition: max-height 1.5s ease cubic-bezier(0, 1.05, 0, 1);
}
.generic-competences {
  margin-top: 0 !important;
  padding-top: 0;
}
.view-more {
  max-height: none !important;
  top: 0 !important;
  transition: max-height 1.5s ease;
}
 .show-th-competences {
  transition-duration: 180ms !important;
  width: 25px;
  height: 12px;
  padding: 0 0 20px 0;
}
.show-th-competences:hover {
  background-color: #838383;
} 
.show-competences {
  background-color: #6c757d;
  margin-left: 10px;
  border-radius: 30px;
  padding: 1px;
}
.show-competences:hover{
  background-color: #838383;
  cursor: pointer !important;
} */
</style>